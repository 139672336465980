import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PageResolverParams } from '../models/page-params.interface';
import { UrlService } from '../services/url.service';

export const pageResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): PageResolverParams => {
  const urlService = inject(UrlService);

  urlService.setUrl(state.url);

  const pageId = urlService.url()?.getUrlPage()?.id || null;
  const language = urlService.url()?.getLanguage() || null;
  //slug asociado al producto
  const ticketName = urlService.url()?.getTicketName() || null;

  return {
    pageId,
    language,
    ticketName,
  };
};
